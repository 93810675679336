// USA
export const locale = {
	lang: 'en',
	data: {
		TRANSLATOR: {
			SELECT: 'Select your language',
		},
		MENU: {
			NEW: 'new',
			ACTIONS: 'Actions',
			CREATE_POST: 'Create New Post',
			REPORTS: 'Reports',
			APPS: 'Apps',
			DASHBOARD: 'Dashboard',
			ORDERS: 'Orders',
			STATS: 'Stats & Reports',
			USERS: 'Users',
			RESTAURANTS: 'Restaurants',
			BUSINESS: 'Business',
			RIDERS: 'Riders',
			STAFF: 'Staff',
			REQUESTS: 'Apply Requests',
			SETTINGS: 'Settings',
			CUSTOMIZATIONS: 'Customizations',
			MEETINGPOINTS: 'Meeting Points'
		},
		AUTH: {
			GENERAL: {
				OR: 'Or',
				SUBMIT_BUTTON: 'Submit',
				NO_ACCOUNT: 'Don\'t have an account?',
				SIGNUP_BUTTON: 'Signup',
				FORGOT_BUTTON: 'Forgot Password',
				BACK_BUTTON: 'Back',
				PRIVACY: 'Privacy',
				LEGAL: 'Legal',
				CONTACT: 'Contact',
			},
			LOGIN: {
				TITLE: 'Login Account',
				BUTTON: 'Sign In',
				HELP_EMAIL: 'Please enter email',
				HELP_PASSWORD: 'Please enter password',
				PASSWORD: 'Password',
			},
			FORGOT: {
				TITLE: 'Forgotten Password?',
				DESC: 'Enter your email to reset your password',
			},
			VALIDATION: {
				INVALID: '{{name}} is not valid',
				REQUIRED: '{{name}} is required',
				MIN_LENGTH: '{{name}} minimum length is {{min}}',
				AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
				NOT_FOUND: 'The requested {{name}} is not found',
				INVALID_LOGIN: 'The login detail is incorrect',
				PASSWORDS_SAME: 'Passwords are the same',
				PASSWORDS_NOT_SAME: 'Passwords are not the same!',
				PASSWORD_LEN: 'Password must be at least 8 chars long',
				PASSWORD_CONFIRM: 'Confirm password',
				PASSWORD_CONFIRM_HELP: 'Please enter confirm password',
				PASSWORD_INSERT_CORRECTLY: 'Insert password correctly!',
			}
		},
		FILTERS: {
			ALL: 'All',
			BYSTATUS: 'Filter by status',
			BYTYPE: 'Filter by type',
			ALLFIELDS: 'Search in all fields',
			BUSINESS: 'Business',
			RIDER: 'Rider',
			ONLINE: 'Online',
			OFFLINE: 'Offline',
			BYCATEGORY: 'Filter by category',
			BYCITY: 'Filter by city',
			BYDATE: 'Filter by date',
			LAST_WEEK: 'Last 7 days',
			LAST_MONTH: 'this month',
			LAST_THREE_MONTHS: 'Last 3 months',
			CUSTOM_RANGE: 'Select a period',
			CLEAR_FILTERS: 'Clear filters',
			NO_DATA_FOR_THIS_FILTER: 'No data for this filters'
		},
		HEADTOOLS: {
			BACK: 'Back',
			SAVE: 'Save',
			CLONE: 'Clone',
			ONLINE: 'On line',
			OFFLINE: 'Off line',
			ADD_NEW_DISH: 'Add new dish',
			EXPORT: 'Export (.CSV)'
		},
		GLOBAL_FIELDS_NAME: {
			YES: 'Yes',
			NO: 'No',
			CANCEL: 'Cancel',
			ICON: 'Icon',
			EMAIL: 'Email',
			EMAIL_HELP: 'Please enter email',
			PHONE: 'Phone',
			PHONE_HELP: 'Please enter phone',
			LOCATION: 'Location',
			LOCATION_HELP: 'Please enter location',
			NAME: 'Name',
			NAME_HELP: 'Please enter name',
			GENDER: 'Gender',
			AGE: 'Age',
			AGE_HELP: 'Please enter age',
			FIRST_NAME: 'Firstname',
			FIRST_NAME_HELP: 'Please enter firstname',
			LAST_NAME: 'Lastname',
			LAST_NAME_HELP: 'Please enter lastname',
			TYPE: 'Type',
			DATE: 'Date',
			STATUS: 'Status',
			RESET_PASSWORD: 'Reset password',
			MALE: 'Male',
			FEMALE: 'Female',
			NORECORDS_FOUND: 'No records found',
			ACTIONS: 'Actions',
			PRICE: 'Price',
			PRICE_HELP: 'Please enter price',
			DESCRIPTION: 'Description',
			DESCRIPTION_HELP: 'Please enter description',
			RECEPTION_READING: 'Received/Read'
		},
		GLOBAL_ADDRESS_FIELDS: {
			ADDRESS_NAME: 'Address name',
			ADDRESS: 'Address',
			CITY: 'City',
			NUMBER: 'Number',
			PROVINCE: 'Province',
			ZIP: 'Zip',
			ADDRESS_HELP: 'Please enter your address',
			CITY_HELP: 'Please enter your city',
			NUMBER_HELP: 'Please enter your number',
			PROVINCE_HELP: 'Please enter your province',
			ZIP_HELP: 'Please enter your zip',
		},
		WIDGET_HOURS: {
			OPENING_HOURS: 'Opening hours:',
			DELIVERY_HOURS: 'Delivery hours:',
			IMPORT_FROM_DELIVERY_HOURS: 'Import from delivery hours',
			IMPORT_FROM_OPENING_HOURS: 'Import from opening hours',
			OPEN: 'Open',
			CLOSED: 'Closed',
			COPY: {
				SUCCESS: 'Hours successfully copied',
				ERROR: 'Hours copy failed'
			},
			IMPORT: {
				SUCCESS: 'Hours successfully imported',
				ERROR: 'Hours import failed'
			}
		},
		DASHBOARD: {},
		REQUESTS: {
			REQUESTS: 'Requests',
			REQUESTS_LIST: 'Requests list',
			FIELDS: {
				TYPE: 'Type',
				NAME: 'Name',
				RESTAURANT_NAME: 'Restaurant Name',
				PHONE: 'Phone',
				EMAIL: 'Email',
				STATUS: 'Status',
				DATE: 'Date',
				ACTIONS: 'Actions',
			},
			REQUEST_STATUS: {
				WAITING: 'Waiting',
				EVALUATION: 'Evaluation',
				PRE_APPROVED: 'Pre-Approved',
				APPROVED: 'Approved',
				REFUSED: 'Refused'
			},
			EVALUATE_REQUEST_SIMPLE: {
				TITLE: 'Evaluate Request',
				DESCRIPTION: 'Are you sure to evaluate this request?',
				WAIT_DESCRIPTION: 'Request is evaluating...',
				MESSAGE: 'Request has been evaluated',
				YESBUTTON: 'Confirm',
				NOBUTTON: 'Cancel'
			},
			PREAPPROVE_REQUEST_SIMPLE: {
				TITLE: 'Pre-approve Request',
				DESCRIPTION: 'Are you sure to pre-approve this request?',
				WAIT_DESCRIPTION: 'Request is pre-approving...',
				MESSAGE: 'Request has been pre-approved',
				YESBUTTON: 'Confirm',
				NOBUTTON: 'Cancel'
			},
			APPROVE_REQUEST_SIMPLE: {
				TITLE: 'Approve Request',
				DESCRIPTION: 'Are you sure to approve this request?',
				WAIT_DESCRIPTION: 'Request is approving...',
				MESSAGE: 'Request has been approved',
				YESBUTTON: 'Confirm',
				NOBUTTON: 'Cancel'
			},
			REFUSE_REQUEST_SIMPLE: {
				TITLE: 'Refuse Request',
				DESCRIPTION: 'Are you sure to refuse this request?',
				WAIT_DESCRIPTION: 'Request is refusing...',
				MESSAGE: 'Request has been refused',
				YESBUTTON: 'Confirm',
				NOBUTTON: 'Cancel'
			},
			DELETE_REQUEST: {
				TITLE: 'Delete Request',
				DESCRIPTION: 'Are you sure to delete this request?',
				WAIT_DESCRIPTION: 'Request is deleting...',
				MESSAGE: 'Request has been deleted',
				YESBUTTON: 'Confirm',
				NOBUTTON: 'Cancel'
			}
		},
		BUSINESS: {
			RESTAURANT: 'Restaurant',
			TABS: {
				PUBLIC_INFO: 'Public Info',
				GALLERY: 'Gallery',
				TIMETABLES: 'Timetables',
				PUBLIC_MENU: 'Public Menù',
				PUBLIC_ADDRESS: 'Public Address',
				SETTINGS: 'Settings',
				BILLING_INFO: 'Billing Info',
				CUSTOMIZATIONS: 'Customizations',
				DISHES: 'Dishes'
			},
			BUSINESSINFO: {
				BUSINESS_NAME: 'Name',
				BUSINESS_NAME_HELP: 'Please enter business name',
				DESCRIPTION: 'Description',
				DESCRIPTION_HELP: 'Please enter description',
				IMAGE: 'Image',
				IMAGE_UPLOAD_SUCCESS: 'Image Uploaded Succesfuly!',
				IMAGE_UPLOAD_FAILED: 'Image Upload Failed!',
				IMAGE_UPLOAD_MAX_SIZE: 'Maximum file size is 2MB!',
				IMAGE_UPLOAD_MAX_WIDTH: 'Minimum width is 1200px!',
				IMAGE_UPLOAD_VERT: 'The image must be horizontal!',
				IMAGE_UPLOAD_MAX_ITEMS: 'You have reached the maximum photo limit for the gallery',
				SELECT_IMAGE: 'Select image',
				SERVICES: 'Restaurant Services',
				CUISINE_TYPES: 'Cuisine types',
				IMAGEBRAND: 'Brand photo',
				SELECT_IMAGE_BRAND: 'Select image'
			},
			MENU: {
				DISH: 'dish',
				ALERT: {
					MISSING_DISHES: 'Missing dishes! Add your first',
					PRESS_SAVE: 'Press SAVE for changes to take effect'
				},
				DISCOUNT_PERCENTAGE: 'Discount Percentage',
				DISCOUNT_PERCENTAGE_HELP: 'Please enter your discount percentage',
				START_DISCOUNT_DATE: 'Start discount date',
				EXPIRE_DISCOUNT_DATE: 'Expire discount date',
				START_DISCOUNT_DATE_HELP: 'Please enter your start discount date',
				EXPIRE_DISCOUNT_DATE_HELP: 'Please enter your expire discount date',
			},
			SETTINGS: {
				MF_PERCENTAGE: 'MF Percentage',
				MF_PERCENTAGE_HELP: 'Please enter your MF Percentage',
				OPTIONS: 'Options',
				CASH_ON_DELIVERY: 'Cash on Delivery',
				PICK_AND_PAY: 'Pick & Pay',
				MF_DELIVERY: 'Moovy Food Delivery',
				SHIPPING_COST: 'Shipping Cost (L):',
				SHIPPING_COST_HELP: 'Please enter shipping cost (L)',
				MINIMUM_ORDER: 'Minimum order (L):',
				MINIMUM_ORDER_HELP: 'Please enter minimum order',
				PREPARATION_TIME: 'Preparation Time (min):',
				PREPARATION_TIME_HELP: 'Please enter preparation time',
				ORDER_PREFIX: 'Order prefix:',
				ORDER_PREFIX_HELP: 'Please enter order prefix',
				MEETINGPOINT: 'Meeting Point',
				MEETINGPOINT_HELP: 'Please select meeting point'
			},
			BILLINGINFO: {
				BUSINESS_NAME: 'Business name:',
				BUSINESS_NAME_HELP: 'Please enter business name',
				VAT: 'Vat:',
				VAT_HELP: 'Please enter vat',
				REFERENCE_PERSON: 'Person reference full name:',
				REFERENCE_PERSON_HELP: ' Please enter person reference full name',
			},
			DISH: {
				EDIT: {
					UPDATE_MESSAGE: 'Dish has been updated',
					ADD_MESSAGE: 'Dish has been created'
				},
			},
			ACTIVATE: {
				TITLE: 'Activate business',
				DESCRIPTION: 'Are you sure to activate this business?',
				WAIT_DESCRIPTION: 'Business is activating...',
				MESSAGE: 'Business has been activated',
				YESBUTTON: 'Confirm',
				NOBUTTON: 'Cancel'
			},
			DEACTIVATE: {
				TITLE: 'Deactivate business',
				DESCRIPTION: 'Are you sure to deactivate this business?',
				WAIT_DESCRIPTION: 'Business is deactivating...',
				MESSAGE: 'Business has been deactivated',
				YESBUTTON: 'Confirm',
				NOBUTTON: 'Cancel'
			},
			RESET_PASSWORD: {
				TITLE: 'Reset User Password',
				DESCRIPTION: 'Are you sure to reset the password?',
				WAIT_DESCRIPTION: 'An email is sending...',
				MESSAGE: 'Email has been sent',
				ERROR: 'Reset password failed',
				YESBUTTON: 'Confirm',
				NOBUTTON: 'Cancel'
			},
			UPDATE: {
				SUCCESS: 'Business successfully has been saved.',
				ERROR: 'Business update failed'
			},
			CREATE: {
				SUCCESS: 'Business successfully has been created.',
				ERROR: 'Business creation failed'
			},
		},
		SERVICES: {
			SERVICES_LIST: 'Services List'
		},
		CUSTOMIZATIONS: {
			CUSTOMIZATIONS: 'Customizations',
			CUSTOMIZATIONS_LIST: 'Customizations list',
			FIELDS: {
				TITLE: 'Title',
				TITLE_HELP: 'Please enter title',
				TYPE: 'Type',
				REQUIRED: 'Required',
				TEXT: 'Text',
				MULTI: 'Multi',
				SINGLE: 'Single'
			},
			DELETE_CUSTOMIZATION_SIMPLE: {
				TITLE: 'Delete Customization',
				DESCRIPTION: 'Are you sure to delete this customization?',
				WAIT_DESCRIPTION: 'Customization is deleting...',
				MESSAGE: 'Customization has been deleted'
			},
			EDIT: {
				UPDATE_MESSAGE: 'Customization has been updated',
				ADD_MESSAGE: 'Customization has been created'
			},
			NEW_CUSTOMIZATION: 'New Customization',
			DETAILS: {
				CUSTOMIZATION_NAME_: 'Edit {{name}}',
				NEW_CUSTOMIZATION: 'New customization'
			}
		},
		DISHES: {
			DISHES: 'Dishes',
			DISHES_LIST: 'Dishes list',
			SELECT_DISH: 'Select dish',
			FIELDS: {
				EXPIRE_DISCOUNT: 'Expire discount date',
				CATEGORY: 'Category',
				CATEGORY_HELP: 'Please enter category',
				DISH: 'Dish',
				DISH_HELP: 'Plase enter dish',
				DISH_PRICE: 'Dish Price',
				DISH_PRICE_HELP: 'Please enter dish price',
				DISCOUNT_PRICE: 'Discount Price',
				DISCOUNT_PRICE_HELP: 'Please enter discount price',
				START_DISCOUNT_DATE: 'Start discount date',
				EXPIRE_DISCOUNT_DATE: 'Expire discount date',
				START_DISCOUNT_DATE_HELP: 'Please enter your start discount date',
				EXPIRE_DISCOUNT_DATE_HELP: 'Please enter your expire discount date',
				DEFAULT_PRICE: 'Default price'
			},
			DETAILS: {
				IMAGE: 'Image',
				IMAGE_UPLOAD_SUCCESS: 'Image Uploaded Succesfuly!',
				IMAGE_UPLOAD_FAILED: 'Image Upload Failed!',
				IMAGE_UPLOAD_MAX_SIZE: 'Maximum file size is 2MB!',
				IMAGE_UPLOAD_MAX_WIDTH: 'Maximum width is 1200px!',
				SELECT_IMAGE: 'Select image',
				AVAILABLE_FOR_ORDER: 'Available for order',
				SERVICES: 'Services',
				DISHES_CATEGORY: 'Dishes category',
				DISHES_CATEGORY_HELP: 'Please enter dish category',
				CUSTOMIZATION: 'Customization',
				DISH_NAME_: 'Edit dish {{name}}',
			},
			DELETE_DISH_SIMPLE: {
				TITLE: 'Delete Dish',
				DESCRIPTION: 'Are you sure to delete this dish?',
				WAIT_DESCRIPTION: 'Dish is deleting...',
				MESSAGE: 'Dish has been deleted'
			},
			CLONE: {
				TITLE: 'Clone Dish',
				DESCRIPTION: 'Are you sure to clone this dish?',
				WAIT_DESCRIPTION: 'Dish is cloning...',
				MESSAGE: 'Dish has been cloned',
				YESBUTTON: 'Clone',
				NOBUTTON: 'Cancel',
				ERROR: 'Dish clone failed'
			},
			UPDATE: {
				SUCCESS: 'Dish has been updated',
				ERROR: 'Dish update failed'
			},
			EDIT: {
				UPDATE_MESSAGE: 'Dish has been updated',
				ADD_MESSAGE: 'Dish has been created'
			},
			ACTIVATE: {
				TITLE: 'Activate Dish',
				DESCRIPTION: 'Are you sure to activate this dish?',
				WAIT_DESCRIPTION: 'Dish is activating...',
				MESSAGE: 'Dish has been activated',
				YESBUTTON: 'Confirm',
				NOBUTTON: 'Cancel'
			},
			DEACTIVATE: {
				TITLE: 'Deactivate Dish',
				DESCRIPTION: 'Are you sure to deactivate this dish?',
				WAIT_DESCRIPTION: 'Dish is deactivating...',
				MESSAGE: 'Dish has been deactivated',
				YESBUTTON: 'Confirm',
				NOBUTTON: 'Cancel'
			},
			NEW_DISH: 'New Dish'
		},
		RIDERS: {
			RIDERS: 'Riders',
			RIDERS_LIST: 'Riders list',
			AVAILABILITY: 'Availability',
			STATUS: {
				AVAILABLE: 'Available',
				WAITING: 'Waiting',
				ASSIGNED: 'Assigned',
				DELIVERING: 'Delivering'
			},
			DETAILS: {
				EDIT_RIDER_: 'Edit rider {{firstname}} {{lastname}}',
				NEW_RIDER: 'New rider',
				MEETINGPOINT: 'Meeting point',
				MEETINGPOINT_HELP: 'Please select meeting point',
				TRANSPORT: 'Transport type',
				TRANSPORT_HELP: 'Please select transport type',
				BIKE: 'Bike',
				SCOOTER: 'Scooter'
			},
			DELETE_RIDER_SIMPLE: {
				TITLE: 'Delete Rider',
				DESCRIPTION: 'Are you sure to delete this rider?',
				WAIT_DESCRIPTION: 'Rider is deleting...',
				MESSAGE: 'Rider has been deleted'
			},
			ACTIVATE: {
				TITLE: 'Activate Rider',
				DESCRIPTION: 'Are you sure to activate this rider?',
				WAIT_DESCRIPTION: 'Rider is activating...',
				MESSAGE: 'Rider has been activated',
				YESBUTTON: 'Confirm',
				NOBUTTON: 'Cancel'
			},
			DEACTIVATE: {
				TITLE: 'Deactivate Rider',
				DESCRIPTION: 'Are you sure to deactivate this rider?',
				WAIT_DESCRIPTION: 'Rider is deactivating...',
				MESSAGE: 'Rider has been deactivated',
				YESBUTTON: 'Confirm',
				NOBUTTON: 'Cancel'
			},
			ONLINE: {
				TITLE: 'Set rider online',
				DESCRIPTION: 'Are you sure to set online this rider?',
				WAIT_DESCRIPTION: 'Rider is setting online...',
				MESSAGE: 'Rider has been sets online',
				YESBUTTON: 'Confirm',
				NOBUTTON: 'Cancel'
			},
			OFFLINE: {
				TITLE: 'Set offline Rider',
				DESCRIPTION: 'Are you sure to set offline this rider?',
				WAIT_DESCRIPTION: 'Rider is setting offline...',
				MESSAGE: 'Rider has been sets offline',
				YESBUTTON: 'Confirm',
				NOBUTTON: 'Cancel'
			},
			RESET_PASSWORD: {
				TITLE: 'Reset User Password',
				DESCRIPTION: 'Are you sure to reset the password?',
				WAIT_DESCRIPTION: 'An email is sending...',
				MESSAGE: 'Email has been sent',
				YESBUTTON: 'Confirm',
				NOBUTTON: 'Cancel'
			},
			UPDATE: {
				SUCCESS: 'Rider successfully has been saved.',
				ERROR: 'Rider update failed',
			},
			PENDING_DELIVERIES: {
				ID_ORDER_HUMAN: 'Order',
				ORDER_TOTAL: 'Total',
				BUSINESS_NAME: 'Business Name',
				BUSINESS_PHONE: 'Business Phone',
				USER_PHONE: 'Customer phone',
				DELIVERY_FROM: 'Start at',
				DELIVERY_TO: 'End at',
				DURATION: 'Duration',
				NO_PENDING: 'No active deliveries for this rider.',

			}
		},
		USERS: {
			USERS: 'Users',
			USERS_LIST: 'Users list',
			FIELDS: {
				NAME: 'Name',
				PHONE: 'Phone',
				EMAIL: 'Email',
				ACTIONS: 'Actions',
			},
			DETAILS: {
				NEW_USER: 'New user',
				EDIT_USER_: 'Edit user {{firstname}} {{lastname}}',
				ADDRESS_TITLE: 'Address',
			},
			UPDATE: {
				SUCCESS: 'User has been succesfully updated',
				ERROR: "User hasn't been succesfully updated",
			},
			DELETE: {
				TITLE: 'Delete User',
				DESCRIPTION: 'Are you sure to delete this user?',
				WAIT_DESCRIPTION: 'User is deleting...',
				MESSAGE: 'User has been deleted',
				YESBUTTON: 'Confirm',
				NOBUTTON: 'Cancel',
				ERROR: 'Delete user failed'
			},
			ACTIVATE: {
				TITLE: 'Activate User',
				DESCRIPTION: 'Are you sure to activate this user?',
				WAIT_DESCRIPTION: 'User is activating...',
				MESSAGE: 'User has been activated',
				YESBUTTON: 'Confirm',
				NOBUTTON: 'Cancel',
				ERROR: 'Activate user failed'
			},
			DEACTIVATE: {
				TITLE: 'Deactivate User',
				DESCRIPTION: 'Are you sure to deactivate this user?',
				WAIT_DESCRIPTION: 'User is deactivating...',
				MESSAGE: 'User has been deactivated',
				YESBUTTON: 'Confirm',
				NOBUTTON: 'Cancel',
				ERROR: 'Deactivate user failed'
			},
			RESET_PASSWORD: {
				TITLE: 'Reset User Password',
				DESCRIPTION: 'Are you sure to reset the password?',
				WAIT_DESCRIPTION: 'An email is sending...',
				MESSAGE: 'An email was sent to your address.',
				ERROR: 'Reset password failed',
				YESBUTTON: 'Confirm',
				NOBUTTON: 'Cancel'
			},
		},
		STAFF: {
			STAFF: 'Staff',
			STAFF_LIST: 'Staff list',
			DETAILS: {
				EDIT_STAFF_: 'Edit staff {{firstname}} {{lastname}}',
				NEW_STAFF: 'New staff'
			},
			DELETE: {
				TITLE: 'Delete Staff',
				DESCRIPTION: 'Are you sure to delete this staff?',
				WAIT_DESCRIPTION: 'Staff is deleting...',
				MESSAGE: 'Staff has been deleted',
				YESBUTTON: 'Confirm',
				NOBUTTON: 'Cancel',
				ERROR: 'Delete staff failed'
			},
			ENABLE: {
				TITLE: 'Activate Staff',
				DESCRIPTION: 'Are you sure to activate this staff?',
				WAIT_DESCRIPTION: 'Staff is activating...',
				MESSAGE: 'Staff has been activated',
				YESBUTTON: 'Confirm',
				NOBUTTON: 'Cancel',
				ERROR: 'Enable staff failed'
			},
			DISABLE: {
				TITLE: 'Deactivate Staff',
				DESCRIPTION: 'Are you sure to deactivate this staff?',
				WAIT_DESCRIPTION: 'Staff is deactivating...',
				MESSAGE: 'Staff has been deactivated',
				YESBUTTON: 'Confirm',
				NOBUTTON: 'Cancel',
				ERROR: 'Disable staff failed'
			},
			RESET_PASSWORD: {
				TITLE: 'Reset User Password',
				DESCRIPTION: 'Are you sure to reset the password?',
				WAIT_DESCRIPTION: 'An email is sending...',
				MESSAGE: 'Email has been sent',
				YESBUTTON: 'Confirm',
				NOBUTTON: 'Cancel'
			},
			UPDATE: {
				SUCCESS: 'Staff successfully has been saved.',
				ERROR: 'Staff update failed'
			},
			CREATE: {
				SUCCESS: 'Staff successfully has been created.',
				ERROR: 'Staff creation failed'
			},
		},
		ORDERS: {
			ORDERS: 'Orders',
			ORDERS_LIST: 'Orders list',
			ORDER_STATUS: {
				ALL: 'ALL',
				WAITING: 'WAITING',
				EXPIRED: 'EXPIRED',
				REFUSED_RESTAURANT: 'REFUSED RESTAURANT',
				CANCEL_USER: 'CANCEL USER',
				REFUSED_USER: 'REFUSED USER',
				CANCEL_MF: 'CANCEL ADMIN/STAFF',
				DELAYED_RESTAURANT: 'DELAYED RESTAURANT',
				ACCEPTED_RESTAURANT: 'ACCEPTED RESTAURANT',
				ACCEPTED_USER: 'ACCEPTED USER',
				READY: 'READY',
				PICKED_USER: 'PICKED_USER',
				DELIVERING: 'DELIVERING',
				DELIVERED: 'DELIVERED',
				NEEDS_RIDER: 'NEEDS RIDER'
			},
			FIELDS: {
				ORDER: '#Order',
				CUSTOMER: 'Customer',
				RESTAURANT: 'Restaurant',
				DATE: 'Date',
				PRICE: 'Price (L)',
				STATUS: 'Status',
				ACTIONS: 'Actions',
			},
			DELETE: {
				TITLE: 'Delete Order',
				DESCRIPTION: 'Are you sure to delete this order?',
				WAIT_DESCRIPTION: 'Order is deleting...',
				MESSAGE: 'Order has been deleted',
				YESBUTTON: 'Confirm',
				NOBUTTON: 'Cancel',
				ERROR: 'Delete order failed'
			},
			ASSIGN_RIDER: {
				SUCCESS: 'Rider successfully has been assigned.',
				ERROR: "Rider hasn't been assigned."
			},
			DETAILS: {
				NEW_ORDER: 'New Order',
				ORDER_N: 'Order {{number}}',
				NEEDS_RIDER: 'This order needs rider!',
				GENERAL_INFO: 'General Info',
				EXPECTED_WITHDRAWAL: 'Expected withdrawal',
				EXCPECTED_DELIVERY: 'Expected delivery',
				SHIPPING_COST: 'Shipping cost',
				TOTAL_PRICE: 'Total price',
				DUTY_COST: 'Duty cost',
				PICKED_BY_CUSTOMER: 'Picked by customer',
				DISHES: 'Dishes',
				SHIPPING_INFO: 'Shipping info',
				SELECT_RIDER: 'Select rider',
				RECEIVED: 'Received',
				READ: 'Read'
			}
		},
		RESTAURANTS: {
			RESTAURANTS_LIST: 'Restaurants list',
			DELETE: {
				TITLE: 'Delete Restaurant',
				DESCRIPTION: 'Are you sure to delete this restaurant?',
				WAIT_DESCRIPTION: 'Restaurant is deleting...',
				MESSAGE: 'Restaurant has been deleted',
				YESBUTTON: 'Confirm',
				NOBUTTON: 'Cancel',
				ERROR: 'Delete restaurant failed'
			},
		},
		PREPARE: {
			IS_MISSING: 'is missing',
		},
		MEETINGPOINTS: {
			MEETINGPOINTS_LIST: 'Meeting points list',
			DETAILS: {
				MEETINGPOINT_NAME_: 'Edit meeting point {{name}}',
				NEW_MEETINGPOINT: 'New meeting point',
			},
			CREATE: {
				SUCCESS: 'MeetingPoint successfully has been creater',
				ERROR: 'MeetingPoint creation failed',
			},
			UPDATE: {
				SUCCESS: 'MeetingPoint successfully has been saved.',
				ERROR: 'MeetingPoint update failed',
			},
			DELETE: {
				TITLE: 'Delete MeetingPoint',
				DESCRIPTION: 'Are you sure to delete this meetingPoint?',
				WAIT_DESCRIPTION: 'MeetingPoint is deleting...',
				MESSAGE: 'MeetingPoint has been deleted',
				YESBUTTON: 'Confirm',
				NOBUTTON: 'Cancel',
				ERROR: 'Delete meetingPoint failed'
			},
		},
		CITIES: {
			CITIES_LIST: 'Cities list',
			DETAILS: {
				NEW_CITY: 'New city',
				CITY_NAME_: 'Edit city {{name}}',
			},
			CREATE: {
				SUCCESS: 'City successfully has been creater',
				ERROR: 'City creation failed',
			},
			UPDATE: {
				SUCCESS: 'City successfully has been saved.',
				ERROR: 'City update failed',
			},
			DELETE: {
				TITLE: 'Delete City',
				DESCRIPTION: 'Are you sure to delete this city?',
				WAIT_DESCRIPTION: 'City is deleting...',
				MESSAGE: 'City has been deleted',
				YESBUTTON: 'Confirm',
				NOBUTTON: 'Cancel',
				ERROR: 'Delete city failed'
			},
		},
		CUISINE_TYPES: {
			CUISINE_TYPES: 'Cuisine Types',
			CUISINE_TYPES_LIST: 'Cuisine Types list',
			ISFOREMOST: 'Is foremost',
			ISACTIVE: 'Is Active',
			DETAILS: {
				CUISINE_TYPES_NAME_: 'Edit {{name}}',
				NEW_CUISINE_TYPES: 'New cuisine type'
			},
			DELETE_CUISINE_TYPE_SIMPLE: {
				TITLE: 'Delete Cuisine Type',
				DESCRIPTION: 'Are you sure to delete this cuisine type?',
				WAIT_DESCRIPTION: 'Cuisine Type is deleting...',
				MESSAGE: 'Cuisine Type has been deleted'
			},
			DELETE_CUISINE_TYPE_MULTY: {
				TITLE: 'Delete Cuisine Types',
				DESCRIPTION: 'Are you sure to permanently delete selected cuisine types?',
				WAIT_DESCRIPTION: 'Cuisine Types are deleting...',
				MESSAGE: 'Selected Cuisine Types have been deleted'
			},
			UPDATE_STATUS: {
				TITLE: 'Cuisine Type has been updated',
				MESSAGE: 'Selected cuisine type have successfully been updated'
			},
			EDIT: {
				UPDATE_MESSAGE: 'Cuisine Types has been updated',
				ADD_MESSAGE: 'Cuisine Types has been created'
			},
			NEW_CUISINE_TYPE: 'New Cuisine Type'
		},
		DISHES_CATEGORIES: {
			DISHES_CATEGORIES: 'Dishes Categories',
			DISHES_CATEGORIES_LIST: 'Dishes Categories list',
			DISH_CATEGORY: 'Dish category',
			DELETE_DISHES_CATEGORY_SIMPLE: {
				TITLE: 'Delete Dishes Category',
				DESCRIPTION: 'Are you sure to delete this dishes category?',
				WAIT_DESCRIPTION: 'Dishes Category is deleting...',
				MESSAGE: 'Dishes Category has been deleted'
			},
			DELETE_DISHES_CATEGORY_MULTY: {
				TITLE: 'Delete Dishes Categories',
				DESCRIPTION: 'Are you sure to permanently delete selected dishes categories?',
				WAIT_DESCRIPTION: 'Dishes Categories are deleting...',
				MESSAGE: 'Selected Categories have been deleted'
			},
			UPDATE: {
				TITLE: 'Dishes category has been updated',
				MESSAGE: 'Selected dishes category have successfully been updated',
				ERROR: 'Update dish failed'
			},
			EDIT: {
				UPDATE_MESSAGE: 'Dishes category has been updated',
				ADD_MESSAGE: 'Dishes category has been created'
			},
			CREATE: {
				SUCCESS: 'Dishes category has been created',
			},
			NEW_DISHES_CATEGORY: 'New Dishes category',
			DETAILS: {
				DISH_CATEGORY_NAME_: 'Edit {{name}}',
				NEW_DISH_CATEGORY: 'New Dish Category',
			},
			FIELDS: {
				TITLE: 'Title',
			}
		},
		PLATFORM_PARAMETERS: {
			PLATFORM_PARAMETERS: 'Platform parameters',
			SHIPPING_COST_PERCENTAGE: 'Shipping cost percentage',
			DELIVERY_DISTANCE_COST: 'Delivery distance cost',
			RIDERS_HOURS: 'Riders Hours',
			FIELDS: {
				CITY_SELECT: 'Select a city',
				DELIVERY_WITH_MF: 'Delivery with MF',
				DELIVERY_WITHOUT_MF: 'Delivery without MF',
				FROM: 'From',
				TO: 'To',
				COST: 'Cost',
				MEETINGPOINT: 'Meeting Point',
				MEETINGPOINT_HELP: 'Please select meeting point'
			}
		},
		PLACEHOLDERS: {
			AGE: 'Enter age',
			FULLNAME: 'Enter fullname',
			EMAIL: 'Enter email address',
			SELECT_IMAGE: 'Select image',
			PASSWORD: 'Enter password',
			CONFIRM_PASSWORD: 'Enter confirmation password',
			DESCRIPTION: 'Enter description',
			NAME: 'Enter name',
			ZIP: 'Enter zip code',
			PROVINCE: 'Enter province',
			CITY: 'Enter city',
			CIVIC_NUMBER: 'Enter address number',
			ADDRESS: 'Enter address',
			VAT: 'Enter VAT',
			BUSINESS_NAME: 'Enter business name',
			PHONE: 'Enter phone number',
			CUISINE_TYPES: 'Enter cuisine types',
			FROM: 'From',
			TO: 'To',
			DISCOUNT_PRICE: 'Discount Price',
			DATE: 'Choose a date',
			MF_PERCENTAGE: 'Enter MF percentage',
			SHIPPING_COST: 'Enter shipping cost',
			MIN_ORDER: 'Enter min order',
			PREPARATION_TIME: 'Enter prepration time',
			ORDER_PREFIX: 'Enter order prefix',
			MEETINGPOINT: 'Select meeting point',
			FIRSTNAME: 'Enter firstname',
			LASTNAME: 'Enter lastname',
			TITLE: 'Enter title',
			TYPE: 'Enter type',
			PRICE: 'Enter price',
			SEARCH:' Search',
			CUSTOMIZAZION: 'Enter customization',
			DISHES_CATEGORY: 'Enter dishes category',
			DISHES: 'Enter dishes',
			RIDER: 'Enter rider',
			PERCENTAGE_WITH_MF: 'Enter percentage with MF',
			PERCENTAGE_WITHOUT_MF: 'Enter percentage without MF',
			DISH_NAME: 'Enter dish name',
			DISH_CATEGORY: 'Enter dish category',
			DISH_PRICE: 'Enter dish price',
			MESSAGE: 'Enter message',
			GENDER: 'Enter gender',
		},
		PREFERRED_LANG: 'Preferred language',
		NOTIFICATIONS: {
			NOTIFICATIONS: 'Notifications',
			TITLE: 'Title',
			MESSAGE: 'Message',
			SEND: {
				BUTTON_LABEL: 'Send',
				SUCCESS: 'Send notification successfully',
				ERROR: 'Send notification failed'
			},
			TYPE: {
				ALL_ACCOUNTS: 'All accounts',
				ALL_USERS: 'All users',
				ALL_RIDERS: 'All riders',
				ALL_BUSINESS: 'All business',
				ALL_IOS: 'All iOS',
				ALL_ANDROID: 'All android',
			}
		},
		FEEDBACKS: {
			FEEDBACK: 'Feedback',
			FEEDBACKS: 'Feedbacks',
			FEEDBACKS_LIST: 'Feedbacks list',
			FIELDS: {
				TOT_FEEDBACK: 'Total feedback',
				COMMENT: 'Comment',
				USER: 'User',
				REQUIRED: 'Required',
				TIMING: 'Timing',
				QUALITY: 'Quality',
				PACKAGE: 'Package'
			},
			ACTIONS: {
				APPROVE: 'approve',
				REFUSE: 'refuse',
				APPROVED_MESSAGE: 'Feedback approved',
				REFUSED_MESSAGE: 'Feedback refuse',
			},
			STATUS: {
				NEW: 'New',
				VOTED: 'Voted',
				APPROVED: 'Approved',
				REFUSED: 'refused',
			}
		},
		PHONE_FORMAT_ERROR: 'Incorrect phone number format',
		CROP: 'Crop'
	}
};
