// Albanese (sq)
export const locale = {
	lang: 'sq',
	data: {
		TRANSLATOR: {
			SELECT: 'Zgjidhni gjuhën tuaj',
		},
		MENU: {
			NEW: 'i ri',
			ACTIONS: 'Veprime',
			CREATE_POST: 'Krijo një post të ri',
			REPORTS: 'Reports',
			APPS: 'Apps',
			DASHBOARD: 'Menu kryesore',
			ORDERS: 'Porosi',
			STATS: 'Statistikat & Raportet',
			USERS: 'Përdoruesit ',
			RESTAURANTS: 'Restorante',
			BUSINESS: 'Biznes',
			RIDERS: 'Transportuesit',
			STAFF: 'Stafi',
			REQUESTS: 'Kërkesat për regjistrimin',
			SETTINGS: 'Impostacione',
			CUSTOMIZATIONS: 'Personalizime',
			MEETINGPOINTS: 'Pikat e takimit'
		},
		AUTH: {
			GENERAL: {
				OR: 'Ose',
				SUBMIT_BUTTON: 'Nënshkruaj',
				NO_ACCOUNT: 'Nuk ke një akount?',
				SIGNUP_BUTTON: 'Regjistrohu',
				FORGOT_BUTTON: 'Keni harruar fjalëkalimin',
				BACK_BUTTON: 'Mbrapa',
				PRIVACY: 'Privacy',
				LEGAL: 'Ligjore',
				CONTACT: 'Kontakt',
			},
			LOGIN: {
				TITLE: 'Login',
				BUTTON: 'Regjistrohu',
				HELP_EMAIL: 'Ju lutemi shkruani adresën tuaj të email-it',
				HELP_PASSWORD: 'Ju lutemi shkruani fjalëkalimin',
				PASSWORD: 'Fjalëkalimi',
			},
			FORGOT: {
				TITLE: 'Keni harruar fjalëkalimin ?',
				DESC: 'Shkruani email-in tuaj për të rivendosur fjalëkalimin tuaj',
			},
			VALIDATION: {
				INVALID: '{{name}} nuk është e vlefshme',
				REQUIRED: '{{name}} është e nevojshme',
				MIN_LENGTH: '{{name}} gjatësia minimale është {{min}}',
				AGREEMENT_REQUIRED: 'Pranimi i terms & conditions janë të nevojshme ',

				NOT_FOUND: 'Kërkesa juaj {{name}} nuk është gjetur',
				INVALID_LOGIN: 'Detaja e identifikimit është e pasaktë',
				PASSWORDS_SAME: 'Fjalëkalimet janë të njëjta',
				PASSWORDS_NOT_SAME: 'Fjalëkalimet nuk janë të njëjta!',
				PASSWORD_LEN: 'Fjalëkalimi duhet të jetë minimum 8 karktere',
				PASSWORD_CONFIRM: 'Konfirmoni fjalëkalimin',
				PASSWORD_CONFIRM_HELP: 'Ju lutemi shkruani dhe konfirmoni fjalëkalimin',
				PASSWORD_INSERT_CORRECTLY: 'Shkruaj fjalëkalimin saktë!',
			}
		},
		FILTERS: {
			ALL: 'Të gjithë',
			BYSTATUS: 'Filtro sipas statusit',
			BYTYPE: 'Filtro sipas llojit',
			ALLFIELDS: 'Kërko në të githa fushat',
			BUSINESS: 'Biznes',
			RIDER: 'Transportuesit',
			ONLINE: 'Online',
			OFFLINE: 'Offline',
			BYCATEGORY: 'Filtro sipas kategorive',
			BYCITY: 'Filtro sipas qyteti',
			BYDATE: 'Filtro sipas data',
			LAST_WEEK: '7 ditët e fundit',
			LAST_MONTH: 'këtë muaj',
			LAST_THREE_MONTHS: 'Tre muajt e fundit',
			CUSTOM_RANGE: 'zgjedhja manuale',
			CLEAR_FILTERS: 'Pastroni filtrat',
			NO_DATA_FOR_THIS_FILTER: 'Nuk ka të dhëna për këtë filtra'
		},
		HEADTOOLS: {
			BACK: 'Mbrapa',
			SAVE: 'Save/ Ruaj',
			CLONE: 'Klono',
			ONLINE: 'On line',
			OFFLINE: 'Off line',
			ADD_NEW_DISH: 'Shto një pjatë të re',
			EXPORT: 'Eksportet (.CSV)'
		},
		GLOBAL_FIELDS_NAME: {
			YES: 'Po',
			NO: 'Jo',
			CANCEL: 'Fshini',
			ICON: 'Ikonë',
			EMAIL: 'Email',
			EMAIL_HELP: 'Ju lutemi shkruani adresën e email-it',
			PHONE: 'Telefon',
			PHONE_HELP: 'Ju lutemi shkruani numrin e telefonit',
			LOCATION: 'Vendi',
			LOCATION_HELP: 'Ju lutemi shkruani vendin',
			NAME: 'Emri',
			NAME_HELP: 'Ju lutemi shkruani emrin',
			GENDER: 'Gjinia',
			AGE: 'Mosha',
			AGE_HELP: 'Ju lutemi shkruani moshen',
			FIRST_NAME: 'Emri',
			FIRST_NAME_HELP: 'Ju lutemi shkruani emrin',
			LAST_NAME: 'Mbiemri',
			LAST_NAME_HELP: 'Ju lutemi shkruani mbiemrin',
			TYPE: 'Lloji',
			DATE: 'Data',
			STATUS: 'Statusi',
			RESET_PASSWORD: 'Rivendosni fjaëkalimin',
			MALE: 'Mashkull',
			FEMALE: 'Femër',
			NORECORDS_FOUND: 'Asnjë rezultat i gjetur',
			ACTIONS: 'Veprime',
			PRICE: 'Çmimi',
			PRICE_HELP: 'Ju lutemi shkruani çmimin',
			DESCRIPTION: 'Përshkrimi',
			DESCRIPTION_HELP: 'Ju lutemi shkruani përshkrimin',
			RECEPTION_READING: 'Njoftohet/Lexoj'
		},
		GLOBAL_ADDRESS_FIELDS: {
			ADDRESS_NAME: 'Emri i adresës',
			ADDRESS: 'Adresa',
			CITY: 'Qyteti',
			NUMBER: 'Numri',
			PROVINCE: 'Rrethi',
			ZIP: 'Kodi postal',
			ADDRESS_HELP: 'Ju lutemi shkruani adresën tuaj',
			CITY_HELP: 'Ju lutemi shkruani qytetin tuaj',
			NUMBER_HELP: 'Ju lutemi shkruani numrin tuaj',
			PROVINCE_HELP: 'Ju lutemi shkruani rrethin tuaj',
			ZIP_HELP: 'Ju lutemi shkruani kodin postal tuaj',
		},
		WIDGET_HOURS: {
			OPENING_HOURS: 'Orari i hapjes:',
			DELIVERY_HOURS: 'Orari i dorëzimit:',
			IMPORT_FROM_DELIVERY_HOURS: 'Importo nga orët e dërgeses',
			IMPORT_FROM_OPENING_HOURS: 'Importo nga orët e hapjes',
			OPEN: 'Hapur',
			CLOSED: 'Mbyllur',
			COPY: {
				SUCCESS: 'Orari u kopjua me sukses',
				ERROR: 'Orari i kopjuar dështoi'
			},
			IMPORT: {
				SUCCESS: 'Orari u importua me sukses',
				ERROR: 'Orari i importuar dështoi'
			}
		},
		DASHBOARD: {},
		REQUESTS: {
			REQUESTS: 'Kërkesa',
			REQUESTS_LIST: 'Lista e kërkesave',
			FIELDS: {
				TYPE: 'Lloji',
				NAME: 'Emri',
				RESTAURANT_NAME: 'Emri i restorantit',
				PHONE: 'Telefon',
				EMAIL: 'Email',
				STATUS: 'Statusi',
				DATE: 'Data',
				ACTIONS: 'Veprime',
			},
			REQUEST_STATUS: {
				WAITING: 'Në pritje',
				EVALUATION: 'Vlerësimi',
				PRE_APPROVED: 'Para-aprovuar',
				APPROVED: 'Aprovuar',
				REFUSED: 'Refuzuar'
			},
			EVALUATE_REQUEST_SIMPLE: {
				TITLE: 'Vlerëso kërkesën',
				DESCRIPTION: 'Jeni i sigurt për të vlerësuar këtë kërkesë?',
				WAIT_DESCRIPTION: 'Kërkesa është vlerësuar...',
				MESSAGE: 'Kërkesa është vlerësuar',
				YESBUTTON: 'Konfirmo',
				NOBUTTON: 'Anulo'
			},
			PREAPPROVE_REQUEST_SIMPLE: {
				TITLE: 'Kërkesë para-aprovimi',
				DESCRIPTION: 'Jeni i sigurt që ta para-aprovoni këtë kërkesë?',
				WAIT_DESCRIPTION: 'Kërkesa është në para-aprovim...',
				MESSAGE: 'Kërkesa është para-aprovuar',
				YESBUTTON: 'Konfirmo',
				NOBUTTON: 'Anulo'
			},
			APPROVE_REQUEST_SIMPLE: {
				TITLE: 'Aprovo kërkesën',
				DESCRIPTION: 'Jeni i sigurt që ta aprovoni këtë kërkesë?',
				WAIT_DESCRIPTION: 'Kërkesa është në aprovacion...',
				MESSAGE: 'Kërkesa është aprovuar',
				YESBUTTON: 'Konfirmo',
				NOBUTTON: 'Anulo'
			},
			REFUSE_REQUEST_SIMPLE: {
				TITLE: 'Refuzo kërkesën',
				DESCRIPTION: 'Jeni i sigurt që të refuzoni këtë kërkesë?',
				WAIT_DESCRIPTION: 'Kërkesa është në refuzim...',
				MESSAGE: 'Kërkesa është refuzuar',
				YESBUTTON: 'Konfirmo',
				NOBUTTON: 'Anulo'
			},
			DELETE_REQUEST: {
				TITLE: 'Anuloni kërkesën',
				DESCRIPTION: 'Jeni i sigurt që të anuloni këtë kërkesë?',
				WAIT_DESCRIPTION: 'Kërkesa është në anulim...',
				MESSAGE: 'Kërkesa është anuluar',
				YESBUTTON: 'Konfirmo',
				NOBUTTON: 'Anulo'
			}
		},
		BUSINESS: {
			RESTAURANT: 'Restorant',
			TABS: {
				PUBLIC_INFO: 'Infromacione publike',
				GALLERY: 'Galeri imazhi',
				TIMETABLES: 'Oraret',
				PUBLIC_MENU: 'Menù publike',
				PUBLIC_ADDRESS: 'Adresa publike',
				SETTINGS: 'Impostacione',
				BILLING_INFO: 'Informacione faturimi',
				CUSTOMIZATIONS: 'Personalizime',
				DISHES: 'Pjata'
			},
			BUSINESSINFO: {
				BUSINESS_NAME: 'Emri',
				BUSINESS_NAME_HELP: 'Ju lutemi shkruani emrin e biznesit',
				DESCRIPTION: 'Përshkrimi',
				DESCRIPTION_HELP: 'Ju lutemi shkruani përshkrimin',
				IMAGE: 'Foto',
				IMAGE_UPLOAD_SUCCESS: 'Fotoja u ngarkua me sukses!',
				IMAGE_UPLOAD_FAILED: 'Ngarkimi i fotos dështoi!',
				IMAGE_UPLOAD_MAX_SIZE: 'Madhësia maksimale të file-it është 2MB!',
				IMAGE_UPLOAD_MAX_WIDTH: 'Gjerësia minimum është 1200px!',
				IMAGE_UPLOAD_VERT: 'Imazhi duhet të jetë horizontale',
				IMAGE_UPLOAD_MAX_ITEMS: 'Keni arritur kufirin maksimal të fotografive për galerinë',
				SELECT_IMAGE: 'Zgjidhni foton',
				SERVICES: 'Shërbimet e restoranti',
				CUISINE_TYPES: 'Llojet e guzhinës',
				IMAGEBRAND: 'Foto e markës',
				SELECT_IMAGE_BRAND: 'Zgjidhni foton'
			},
			MENU: {
				DISH: 'pjatë',
				ALERT: {
					MISSING_DISHES: 'Pjata munguese! Shtoni të parën tuaj! ',
					PRESS_SAVE: 'Shtypni SAVE/RUAJ për të bërë efektive modifikimet'
				},
				DISCOUNT_PERCENTAGE: 'Përqindja e uljes',
				DISCOUNT_PERCENTAGE_HELP: 'Ju lutemi shkruani përqindjen tuaj të uljes',
				START_DISCOUNT_DATE: 'Data e nisjes së uljes',
				EXPIRE_DISCOUNT_DATE: 'Data e skadimit të uljes',
				START_DISCOUNT_DATE_HELP: 'Ju lutemi shkruani your start discount date',
				EXPIRE_DISCOUNT_DATE_HELP: 'Ju lutemi shkruani datën e skadimit të uljes',
			},
			SETTINGS: {
				MF_PERCENTAGE: 'Përqindja e MF',
				MF_PERCENTAGE_HELP: 'Ju lutemi shkruani pëqindjen e MF ',
				OPTIONS: 'Opsione',
				CASH_ON_DELIVERY: 'Pagesa në Dorëzim ',
				PICK_AND_PAY: 'Pick & Pay',
				MF_DELIVERY: 'Dorëzim nga Moovy Food',
				SHIPPING_COST: 'Kojstoja e transportit(L):',
				SHIPPING_COST_HELP: 'Ju lutemi shkruani koston e trasportit(L)',
				MINIMUM_ORDER: 'Porosia minimale (L):',
				MINIMUM_ORDER_HELP: 'Ju lutemi shkruani porosin minimale',
				PREPARATION_TIME: 'Koha e përgatitjes (min):',
				PREPARATION_TIME_HELP: 'Ju lutemi shkruani kohën e përgatitjes',
				ORDER_PREFIX: 'Prefiksi i porosisë:',
				ORDER_PREFIX_HELP: 'Ju lutemi shkruani prefiksin e porosisë',
				MEETINGPOINT: 'Pika takimi',
				MEETINGPOINT_HELP: 'Ju lutemi caktoni pikën e takimit'
			},
			BILLINGINFO: {
				BUSINESS_NAME: 'Emri i biznesit:',
				BUSINESS_NAME_HELP: 'Ju lutemi shkruani emmrin e biznesit',
				VAT: 'TVSH:',
				VAT_HELP: 'Ju lutemi shkruani TVSH',
				REFERENCE_PERSON: 'Emri i plotë i referencës së personit:',
				REFERENCE_PERSON_HELP: 'Ju lutemi shkruani emrin e plotë të referencës së personit',
			},
			DISH: {
				EDIT: {
					UPDATE_MESSAGE: 'Pjata është përditësuar',
					ADD_MESSAGE: 'Pjata është krijuar'
				},
			},
			ACTIVATE: {
				TITLE: 'Aktivizo biznesin',
				DESCRIPTION: 'Jeni i sigurt të aktivizoni biznesin?',
				WAIT_DESCRIPTION: 'Biznesi është në aktivizim...',
				MESSAGE: 'Biznesi është aktivizuar',
				YESBUTTON: 'Konfirmo',
				NOBUTTON: 'Anulo'
			},
			DEACTIVATE: {
				TITLE: 'Çaktivizo biznesin',
				DESCRIPTION: 'Jeni i sigurt të çaktivizoni bisnesin?',
				WAIT_DESCRIPTION: 'Biznesi është në çaktivizim...',
				MESSAGE: 'Biznesi është çaktivizuar',
				YESBUTTON: 'Konfirmo',
				NOBUTTON: 'Anulo'
			},
			RESET_PASSWORD: {
				TITLE: 'Rivendos fjalëkalimin e përdoruesit',
				DESCRIPTION: 'Jeni i sigurt të rivendosni fjalëkalimin?',
				WAIT_DESCRIPTION: 'Emal-i është në dergim...',
				MESSAGE: 'Email-i u dërgua',
				ERROR: 'Rivendosja e fjalëkalimit dështoi',
				YESBUTTON: 'Konfirmo',
				NOBUTTON: 'Anulo'
			},
			UPDATE: {
				SUCCESS: 'Biznesi u regjistrua me sukses.',
				ERROR: 'Përditësimi i biznesit dështoi'
			},
			CREATE: {
				SUCCESS: 'Biznesi u krijua me sukses.',
				ERROR: 'Krijimi i biznesit dështoi '
			},
		},
		SERVICES: {
			SERVICES_LIST: 'Lista e shërbimeve'
		},
		CUSTOMIZATIONS: {
			CUSTOMIZATIONS: 'Personalizimi',
			CUSTOMIZATIONS_LIST: 'Lista e personalizimit',
			FIELDS: {
				TITLE: 'Titulli',
				TITLE_HELP: 'Ju lutemi shkruani titullin',
				TYPE: 'Lloji',
				REQUIRED: 'Kërkesa',
				TEXT: 'Tekst',
				MULTI: 'Multi',
				SINGLE: 'Single'
			},
			DELETE_CUSTOMIZATION_SIMPLE: {
				TITLE: 'Anulo personalizimin',
				DESCRIPTION: 'Jeni i sigurt të anuloni personalizimin ?',
				WAIT_DESCRIPTION: 'Personalizimi është ne anulim...',
				MESSAGE: 'Personalizimi është anuluar'
			},
			EDIT: {
				UPDATE_MESSAGE: 'Personalizimi është përditësuar',
				ADD_MESSAGE: 'Personalizimi është krijuar'
			},
			NEW_CUSTOMIZATION: 'Personalizim i ri',
			DETAILS: {
				CUSTOMIZATION_NAME_: 'Modifikim {{name}}',
				NEW_CUSTOMIZATION: 'Personalizim i ri'
			}
		},
		DISHES: {
			DISHES: 'Pjata',
			DISHES_LIST: 'Lista e pjatave',
			SELECT_DISH: 'Zgjidh pjatën',
			FIELDS: {
				EXPIRE_DISCOUNT: 'Data e skadimit të uljes',
				CATEGORY: 'Kategoria',
				CATEGORY_HELP: 'Ju lutemi shkruani kategorinë',
				DISH: 'Pjatë',
				DISH_HELP: 'Ju lutemi shkruani pjatën',
				DISH_PRICE: 'Çmimi i Pjatës',
				DISH_PRICE_HELP: 'Ju lutemi shkruani çmimin e pjatës',
				DISCOUNT_PRICE: 'Ulje çcmimi',
				DISCOUNT_PRICE_HELP: 'Ju lutemi shkruani uljen e çmimit',
				START_DISCOUNT_DATE: 'Nisja e datës së uljes',
				EXPIRE_DISCOUNT_DATE: 'Skadenca e datës së uljes',
				START_DISCOUNT_DATE_HELP: 'Ju lutemi shkruani datën e filimit të ulje',
				EXPIRE_DISCOUNT_DATE_HELP: 'Ju lutemi shkruani datën e skadencës së uljes',
				DEFAULT_PRICE: 'Çmimi i parazgjedhur'
			},
			DETAILS: {
				IMAGE: 'Foto',
				IMAGE_UPLOAD_SUCCESS: 'Fotoja u ngarkua me sukses!',


				IMAGE_UPLOAD_FAILED: 'Ngarkimi i fotos dështoi!',
				IMAGE_UPLOAD_MAX_SIZE: 'Madhësia maksimale të file-it është 2MB!',
				IMAGE_UPLOAD_MAX_WIDTH: 'Gjerësia maksimale është 1200px!',
				SELECT_IMAGE: 'Zgjidhni foton',
				AVAILABLE_FOR_ORDER: 'Në dispozicion për porosi',
				SERVICES: 'Shërbime',
				DISHES_CATEGORY: 'Kategoria e pjatave',
				DISHES_CATEGORY_HELP: 'Ju lutemi shkruani kategorinë e pjatave',
				CUSTOMIZATION: 'Personalizime',
				DISH_NAME_: 'Modifiko pjatën {{name}}',
			},
			DELETE_DISH_SIMPLE: {
				TITLE: 'Anulo pjaten',
				DESCRIPTION: 'Jeni i sigurt të anuloni këtë pjatë?',
				WAIT_DESCRIPTION: 'Pjata është në anulim...',
				MESSAGE: 'Pjata është anuluar'
			},
			CLONE: {
				TITLE: 'Kolono pjatën',
				DESCRIPTION: 'Jeni i sigurt të klononi këtë pjatë?',
				WAIT_DESCRIPTION: 'Pjata është në klonim...',
				MESSAGE: 'Pjata është klonuar',
				YESBUTTON: 'Klono',
				NOBUTTON: 'Anulo',
				ERROR: 'Klonimi i pjatës dështoi'
			},
			UPDATE: {
				SUCCESS: 'Pjata është përditësuar',
				ERROR: 'Përditësimi i pjatës dështoi'
			},
			EDIT: {
				UPDATE_MESSAGE: 'Pjata është përditësuar',
				ADD_MESSAGE: 'Pjata është krijuar'
			},
			ACTIVATE: {
				TITLE: 'Aktivizo pjatën',
				DESCRIPTION: 'Jeni i sigurt të aktivizoni këtë pjatë?',
				WAIT_DESCRIPTION: 'Pjata është në aktivizim...',
				MESSAGE: 'Pjata është aktivizuar',
				YESBUTTON: 'Konfimo',
				NOBUTTON: 'Anulo'
			},
			DEACTIVATE: {
				TITLE: 'Çaktivizo pjatën',
				DESCRIPTION: 'Jeni i sigurt të çaktivizoni këtë pjatë?',
				WAIT_DESCRIPTION: 'Pjata është në çaktivizim...',
				MESSAGE: 'Pjata është çaktivizuar',
				YESBUTTON: 'Konfirmo',
				NOBUTTON: 'Anulo'
			},
			NEW_DISH: 'Pjatë e re'
		},
		RIDERS: {
			RIDERS: 'Transportuesit',
			RIDERS_LIST: 'Lista e transportuesve',
			AVAILABILITY: 'Disponueshmëria',
			STATUS: {
				AVAILABLE: 'Disponueshëm',
				WAITING: 'Në pritje',
				ASSIGNED: 'Caktuar',
				DELIVERING: 'Në dorëzim'
			},
			DETAILS: {
				EDIT_RIDER_: 'Modifiko transportator {{firstname}} {{lastname}}',
				NEW_RIDER: 'Transportator i ri',
				MEETINGPOINT: 'Pika takimi',
				MEETINGPOINT_HELP: 'Ju lutemi caktoni një pikë takimi',
				TRANSPORT: 'Lloji i mjetit të trasportit',
				TRANSPORT_HELP: 'Ju lutemi caktoni llojin e mjetit të transportit',
				BIKE: 'Biçikletë',
				SCOOTER: 'Motor'
			},
			DELETE_RIDER_SIMPLE: {
				TITLE: 'Fshij transpotuesin',
				DESCRIPTION: 'Jeni i sigurt të fshini transportuesin?',
				WAIT_DESCRIPTION: 'Transportuesi është në fshirje...',
				MESSAGE: 'Trnasportuesi është fshirë'
			},
			ACTIVATE: {
				TITLE: 'Aktivizo transportuesin',
				DESCRIPTION: 'Jeni i sigurt të aktivizoni transportuesin?',
				WAIT_DESCRIPTION: 'Transportuesi është në aktivizim...',
				MESSAGE: 'Transportuesi është aktivizuar',
				YESBUTTON: 'Konfirmo',
				NOBUTTON: 'Anulo'
			},
			DEACTIVATE: {
				TITLE: 'Çaktivizo trnaspotuesin',
				DESCRIPTION: 'Jeni i sigurt të çaktivizoni këtë transportues?',
				WAIT_DESCRIPTION: 'Transportuesi është në çaktivizim...',
				MESSAGE: 'Transportuesi është çaktivizuar',
				YESBUTTON: 'Konfirmo',
				NOBUTTON: 'Anulo'
			},
			ONLINE: {
				TITLE: 'Transportues online',
				DESCRIPTION: 'Jeni i sigurt të vendosni online transportuesin?',
				WAIT_DESCRIPTION: 'Transportuesi është vënë online online...',
				MESSAGE: 'Transportuesi është online',
				YESBUTTON: 'Konfirm',
				NOBUTTON: 'Anulo'
			},
			OFFLINE: {
				TITLE: 'Vendos offline transportuesin',
				DESCRIPTION: 'Jeni i sigurt të vendosni offline trnasportuesin?',
				WAIT_DESCRIPTION: 'Transportuesi është vënë offline...',
				MESSAGE: 'Transportuesi është offline',
				YESBUTTON: 'Konfirmo',
				NOBUTTON: 'Anulo'
			},
			RESET_PASSWORD: {
				TITLE: 'Reset User Password',
				DESCRIPTION: 'Jeni i sigurt të rivendosni fjalëkalimin?',
				WAIT_DESCRIPTION: 'Email-i është në dërgim...',
				MESSAGE: 'Email-i është dërguar',
				YESBUTTON: 'Konfirmo',
				NOBUTTON: 'Anulo'
			},
			UPDATE: {
				SUCCESS: 'Transportuesi është regjistruar me sukses.',
				ERROR: 'Përditësimi i transportuesit dështoi',
			},
			PENDING_DELIVERIES: {
				ID_ORDER_HUMAN: 'Porosi',
				ORDER_TOTAL: 'Total',
				BUSINESS_NAME: 'Emri i biznesit',
				BUSINESS_PHONE: 'Numër telefoni të biznesit',
				USER_PHONE: 'Numër telefoni të clienttit',
				DELIVERY_FROM: 'Dorëzimi nga',
				DELIVERY_TO: 'Dërgimi në',
				DURATION: 'Kohëzgjatja',
				NO_PENDING: 'Asnjë dorëzim aktiv për këtë transportues.',

			}
		},
		USERS: {
			USERS: 'Përdoruesit',
			USERS_LIST: 'Lista e përdoruesve',
			FIELDS: {
				NAME: 'Emri',
				PHONE: 'Telefon',
				EMAIL: 'Email',
				ACTIONS: 'Veprime',
			},
			DETAILS: {
				NEW_USER: 'Përdorues i ri',
				EDIT_USER_: 'Modifiko përdoruesin {{firstname}} {{lastname}}',
				ADDRESS_TITLE: 'Adresa',
			},
			UPDATE: {
				SUCCESS: 'Përdoruesit janë përditësuar me sukses',
				ERROR: "Përditësimi i përdoruesit dështoi ",
			},
			DELETE: {
				TITLE: 'Anulo përdoruesin',
				DESCRIPTION: 'Jeni i sigurt të anuloni këtë përdorues?',
				WAIT_DESCRIPTION: 'Përdoruesi është në anulim...',
				MESSAGE: 'Përdoruesi u anulua',
				YESBUTTON: 'Konfirmo',
				NOBUTTON: 'Anulo',
				ERROR: 'Anulimi i përdoruesit dështoi'
			},
			ACTIVATE: {
				TITLE: 'Aktivizo përdoruesin',
				DESCRIPTION: 'Jeni i sigurt të aktivizoni këtë përdorues?',
				WAIT_DESCRIPTION: 'Përdoruesi është në aktivizim...',
				MESSAGE: 'Përdoruesi është aktivizuar',
				YESBUTTON: 'Konfirmo',
				NOBUTTON: 'Anulo',
				ERROR: 'Aktivizimi i përdoruesit dështoi'
			},
			DEACTIVATE: {
				TITLE: 'Çaktivizo përdoruesin',
				DESCRIPTION: 'Jeni i sigurt të çcaktivizoni këtë përdorues?',
				WAIT_DESCRIPTION: 'Përdoruesi është në çaktivizim...',
				MESSAGE: 'Përdoruesi është çaktivizuar',
				YESBUTTON: 'Konfirmo',
				NOBUTTON: 'Anulo',
				ERROR: 'Çaktivizimi i përdoruesit dështoi'
			},
			RESET_PASSWORD: {
				TITLE: 'Reset User Password',
				DESCRIPTION: 'Jeni i sigurt të rivendosni fjalëkalimin?',
				WAIT_DESCRIPTION: 'Email-i është në dërgim...',
				MESSAGE: 'Një email është dërguar në adresën tuaj.',
				ERROR: 'Anulimi i fjalëkalimit dështoi',
				YESBUTTON: 'Konfirmo',
				NOBUTTON: 'Anulo'
			},
		},
		STAFF: {
			STAFF: 'Stafi',
			STAFF_LIST: 'Lista e stafit',
			DETAILS: {
				EDIT_STAFF_: 'Modifiko stafin {{firstname}} {{lastname}}',
				NEW_STAFF: 'Staf i ri'
			},
			DELETE: {
				TITLE: 'Anulo stafin',
				DESCRIPTION: 'Jeni i sigurt të fshini këtv staf delete this staff?',
				WAIT_DESCRIPTION: 'Stafi është në fshirje...',
				MESSAGE: 'Stafi është fshirë',
				YESBUTTON: 'Konfirmo',
				NOBUTTON: 'Anulo',
				ERROR: 'Anulimi i stafit dështoi'
			},
			ENABLE: {
				TITLE: 'Aktivizo stafin',
				DESCRIPTION: 'Jeni i sigurt të aktivizoni këtë staf?',
				WAIT_DESCRIPTION: 'Stafi është në ativizim...',
				MESSAGE: 'Stafi është aktivizuar',
				YESBUTTON: 'Konfirmo',
				NOBUTTON: 'Anulo',
				ERROR: 'Aktivizimi i stafit dështoi'
			},
			DISABLE: {
				TITLE: 'Çaktivizo stafin',
				DESCRIPTION: 'Jeni i sigurt të çaktivizoni stafin?',
				WAIT_DESCRIPTION: 'Stafi është në çaktivizim...',
				MESSAGE: 'Stafi është çaktivizuar',
				YESBUTTON: 'Konfirmo',
				NOBUTTON: 'Anulo',
				ERROR: 'Çaktivizimi i stafit dështoi'
			},
			RESET_PASSWORD: {
				TITLE: 'Reset User Password',
				DESCRIPTION: 'Jeni i sigurt të rivendosni fjalëkalimin?',
				WAIT_DESCRIPTION: 'Një email është në dërgim...',
				MESSAGE: 'Email-i është dërguar',
				YESBUTTON: 'Konfirmo',
				NOBUTTON: 'Anulo'
			},
			UPDATE: {
				SUCCESS: 'Stafi u regjistrua me sukses.',
				ERROR: 'Përditësimi i stafit dështoi '
			},
			CREATE: {
				SUCCESS: 'Stafi u regjistrua me sukses.',
				ERROR: 'Regjistrimi i stafit dështoi'
			},
		},
		ORDERS: {
			ORDERS: 'Porosi',
			ORDERS_LIST: 'Lista e porosive',
			ORDER_STATUS: {
				ALL: 'Të gjithë',
				WAITING: 'Në pritje',
				EXPIRED: 'Skadoi',
				REFUSED_RESTAURANT: 'Refuzoi restoranti',
				CANCEL_USER: 'Anulo përdoruesin',
				REFUSED_USER: 'Refuzo pvrdoruesin',
				CANCEL_MF: 'Anulo ADMIN/STAFF',
				DELAYED_RESTAURANT: 'Vonesë nga restoranti',
				ACCEPTED_RESTAURANT: 'Pranuar nga restoranti',
				ACCEPTED_USER: 'Pranuar nga përdoruesit',
				READY: 'Gati',
				PICKED_USER: 'Zgjedhur nga përdoruesit',
				DELIVERING: 'Në dërzim',
				DELIVERED: 'Dorëzuar',
				NEEDS_RIDER: 'Nevojiten transportues'
			},
			FIELDS: {
				ORDER: '#Porosi',
				CUSTOMER: 'Klient',
				RESTAURANT: 'Restorant',
				DATE: 'Data',
				PRICE: 'Çmimi(L)',
				STATUS: 'Statusi',
				ACTIONS: 'Veprime',
			},
			DELETE: {
				TITLE: 'Anulo porosinë',
				DESCRIPTION: 'Jeni i sigurt të anuloni këtë poeosi?',
				WAIT_DESCRIPTION: 'Porosia është anulim...',
				MESSAGE: 'Porosia është anuluar',
				YESBUTTON: 'Konfirmo',
				NOBUTTON: 'Anulo',
				ERROR: 'Anulimi i porosisë dështoi'
			},
			ASSIGN_RIDER: {
				SUCCESS: 'Transportuesi u caktua me sukses.',
				ERROR: "Caktimi i transportuesit dështoi."
			},
			DETAILS: {
				NEW_ORDER: 'Porosi e re',
				ORDER_N: 'Porosi {{number}}',
				NEEDS_RIDER: 'Kjo porosi ka nevojë per transportues!',
				GENERAL_INFO: 'Informacion i përgjithshëm',
				EXPECTED_WITHDRAWAL: 'Tërheqja e parashikuar',
				EXCPECTED_DELIVERY: 'Dorëzimi i parashikuar',
				SHIPPING_COST: 'Kosto e transportit',
				TOTAL_PRICE: 'Çmimi total',
				DUTY_COST: 'Kosto shërbimi',
				PICKED_BY_CUSTOMER: 'Tërhequr nga klienti',
				DISHES: 'Pjata',
				SHIPPING_INFO: 'Informacione transporti',
				SELECT_RIDER: 'zgjidh transportuesin',
				RECEIVED: 'Marrë',
				READ: 'Lexoj'
			}
		},
		RESTAURANTS: {
			RESTAURANTS_LIST: 'Lista e restoranteve',
			DELETE: {
				TITLE: 'Anulo restorantin',
				DESCRIPTION: 'Jeni i sigurt të anuloni këtë restorant?',
				WAIT_DESCRIPTION: 'Restoranti është në anulim...',
				MESSAGE: 'Restaurant është anuluar',
				YESBUTTON: 'Konfirmo',
				NOBUTTON: 'Anulo',
				ERROR: 'Anulimi i restorantit dështoi'
			},
		},
		PREPARE: {
			IS_MISSING: 'mungon',
		},
		MEETINGPOINTS: {
			MEETINGPOINTS_LIST: 'Lista e pikave të takimeve',
			DETAILS: {
				MEETINGPOINT_NAME_: 'Modifiko pikën e takimit {{name}}',
				NEW_MEETINGPOINT: 'Pikë takimi i ri',
			},
			CREATE: {
				SUCCESS: 'Pika e takimit u krijua me sukses',
				ERROR: 'Krijimi i pikës së takimit dështoi',
			},
			UPDATE: {
				SUCCESS: 'Pika e takimit u regjistrua.',
				ERROR: 'Përditësimi i pikës së takimit dështoi',
			},
			DELETE: {
				TITLE: 'Anulo pikën e takimit',
				DESCRIPTION: 'Jeni i sigurt të anuloni këtë pikë takimi?',
				WAIT_DESCRIPTION: 'Pika e takimit është në anulim...',
				MESSAGE: 'Pika e takimit është anuluar',
				YESBUTTON: 'Konfirmo',
				NOBUTTON: 'Anulo',
				ERROR: 'Anulimi i pikës së takimit dështoi'
			},
		},
		CITIES: {
			CITIES_LIST: 'Lista e qytetëve',
			DETAILS: {
				NEW_CITY: 'Qytet i ri',
				CITY_NAME_: 'Modifiko qytetin {{name}}',
			},
			CREATE: {
				SUCCESS: 'Qyteti u krijua me sukses',
				ERROR: 'Krijimi i qytetit dështoi',
			},
			UPDATE: {
				SUCCESS: 'Qyteti u regjistrua me sukses.',
				ERROR: 'Përditësimi i qytetit dështoi',
			},
			DELETE: {
				TITLE: 'Anulo Qytetin',
				DESCRIPTION: 'Jeni i sigurt të anuloni këtë qytet?',
				WAIT_DESCRIPTION: 'Qyteti është në anulim...',
				MESSAGE: 'Qyteti është anuluar',
				YESBUTTON: 'Konfirmo',
				NOBUTTON: 'Anulo',
				ERROR: 'Anulimi i qytetit falimentoi'
			},
		},
		CUISINE_TYPES: {
			CUISINE_TYPES: 'Lloj guzhine',
			CUISINE_TYPES_LIST: 'Lista e llojeve të guzhinës',
			ISFOREMOST: 'është kryesorja',
			ISACTIVE: 'aktiv',
			DETAILS: {
				CUISINE_TYPES_NAME_: 'Modifiko {{name}}',
				NEW_CUISINE_TYPES: 'Lloj guzhine e re'
			},
			DELETE_CUISINE_TYPE_SIMPLE: {
				TITLE: 'Anulo llojin e guzhinës',
				DESCRIPTION: 'Jeni i sigurt të anuloni këtë lloj guzhine?',
				WAIT_DESCRIPTION: 'Lloji i guzhinës u anulua...',
				MESSAGE: 'Lloji i guzhinës është anuluar'
			},
			DELETE_CUISINE_TYPE_MULTY: {
				TITLE: 'Anulo llojin e guzhinës',
				DESCRIPTION: 'Jeni i sigurt të anuloni në mënyrë permanente këtë lloj guzhine?',
				WAIT_DESCRIPTION: 'Lloji i guzhinës është në anulim...',
				MESSAGE: 'Lloji i guzhinës zgjedhur është anuluar'
			},
			UPDATE_STATUS: {
				TITLE: 'Lloji i guzhinës është përditësuar',
				MESSAGE: 'Lloji i guzhines zgjedhur është përditësuar me sukses'
			},
			EDIT: {
				UPDATE_MESSAGE: 'Lloji i guzhinës është përditësuar',
				ADD_MESSAGE: 'Lloji i guzhinës është krijuar'
			},
			NEW_CUISINE_TYPE: 'Lloj guzhine e re'
		},
		DISHES_CATEGORIES: {
			DISHES_CATEGORIES: 'Kategoria e pjatave',
			DISHES_CATEGORIES_LIST: 'Lista e kategorisë së pjatave',
			DISH_CATEGORY: 'Kategoria e pjatave',
			DELETE_DISHES_CATEGORY_SIMPLE: {
				TITLE: 'Anulo kategorinë e pjatave',
				DESCRIPTION: 'Jeni i sigurt të anuloni këtë gategori pjatash?',
				WAIT_DESCRIPTION: 'Kategoria e pjatave është në anulim...',
				MESSAGE: 'Kategoria e pjatave është anuluar'
			},
			DELETE_DISHES_CATEGORY_MULTY: {
				TITLE: 'Anuloni kategorinë e pjatave',
				DESCRIPTION: 'Jeni i sigurt të anulononi në mënyrë permanente këtë kategori pjatash?',
				WAIT_DESCRIPTION: 'Kategoria e pjatave është n...',
				MESSAGE: 'Kategoritë e zgjdhura janë fshirë'
			},
			UPDATE: {
				TITLE: 'Kategoria e pjatave është përditësuar',
				MESSAGE: 'Zgjedhja e kategorisë së pjatave është përditësuar me sukses',
				ERROR: 'Përditësimi i pjatës dështoi'
			},
			EDIT: {
				UPDATE_MESSAGE: 'Kategoria e pjatave është përditësuar',
				ADD_MESSAGE: 'Kategoria e pjatave është krijuar'
			},
			CREATE: {
				SUCCESS: 'Kategoria e pjatave është krijuar',
			},
			NEW_DISHES_CATEGORY: 'Kategori e re pjatash',
			DETAILS: {
				DISH_CATEGORY_NAME_: 'Modifiko {{name}}',
				NEW_DISH_CATEGORY: 'Kategori e re pjatash',
			},
			FIELDS: {
				TITLE: 'Titulli',
			}
		},
		PLATFORM_PARAMETERS: {
			PLATFORM_PARAMETERS: 'Parameterat e platformës',
			SHIPPING_COST_PERCENTAGE: 'Përqindja e shpenzimeve të transportit',
			DELIVERY_DISTANCE_COST: 'Kostoja e distancës së dorëzimit',
			RIDERS_HOURS: 'Orët e transportuesve',
			FIELDS: {
				CITY_SELECT: 'Zgjidhni një qytet',
				DELIVERY_WITH_MF: 'Dorëzim nga MF',
				DELIVERY_WITHOUT_MF: 'Dorëzim pa MF',
				FROM: 'Nga',
				TO: 'Në',
				COST: 'Kosto',
				MEETINGPOINT: 'Pikë takimi',
				MEETINGPOINT_HELP: 'Ju lutemi caktoni një pikë takimi'
			}
		},
		PLACEHOLDERS: {
			AGE: 'Shkruani moshën',
			FULLNAME: 'Shkruani emër dhe mbiemër',
			EMAIL: 'Shkruani adresën e email-it',
			SELECT_IMAGE: 'Zgjidhni një foto',
			PASSWORD: 'Shkruani fjalëkalimin',
			CONFIRM_PASSWORD: 'Shkruani fjalëkalimin e kofirmimit',
			DESCRIPTION: 'Shkruani përshkrimin',
			NAME: 'Shkruani emrin',
			ZIP: 'Shkruani kodin postal',
			PROVINCE: 'Shkruani provincën',
			CITY: 'Shkruani qytetin',
			CIVIC_NUMBER: 'Shkruani numrin e adresës',
			ADDRESS: 'Shkruani adresën',
			VAT: 'Shkruani TVSH',
			BUSINESS_NAME: 'Shkruani emrin e biznesit',
			PHONE: 'Shkruani numrin e telefonit',
			CUISINE_TYPES: 'Shkruani llojin e guzhinës',
			FROM: 'Nga',
			TO: 'Në',
			DISCOUNT_PRICE: 'Ulje çmimi',
			DATE: 'Zgjidh një datë',
			MF_PERCENTAGE: 'Shkruani përqindjen e MF',
			SHIPPING_COST: 'Shkruani koston e transportit',
			MIN_ORDER: 'Shkruani minimumin e porosisë',
			PREPARATION_TIME: 'Shkruani kohën e përgatitjes',
			ORDER_PREFIX: 'Shkruani prefiksin e porosisë',
			MEETINGPOINT: 'Caktoni një pikë takimi',
			FIRSTNAME: 'Shkruani emrin',
			LASTNAME: 'Shkruani mbiemrin',
			TITLE: 'Shkruani titullin',
			TYPE: 'Shkruani llojin',
			PRICE: 'Shkruani çmimin',
			SEARCH: 'Kërko',
			CUSTOMIZAZION: 'Shkruani personalizimin',
			DISHES_CATEGORY: 'Shkruani kategoritë e pjatave',
			DISHES: 'Shkruani pjatat',
			RIDER: 'Shkruani trnsportuesit',
			PERCENTAGE_WITH_MF: 'Shkruani përqindjen me MF',
			PERCENTAGE_WITHOUT_MF: 'Shkruani përqindjen pa MF',
			DISH_NAME: 'Shkruani emrin e pjatës',
			DISH_CATEGORY: 'Shkruani kategorinë e pjatës',
			DISH_PRICE: 'Shkruani çmimin e pjatës',
			MESSAGE: 'Shkruaj mesazh',
			GENDER: 'Shkruaj gjininÎ',
		},
		PREFERRED_LANG: 'Gjuha e preferuar',
		NOTIFICATIONS: {
			NOTIFICATIONS: 'Njoftime',
			TITLE: 'Titulli',
			MESSAGE: 'Mesazh',
			SEND: {
				BUTTON_LABEL: 'DÎrguar',
				SUCCESS: 'DÎrgimi i njoftimit shkoi me sukses',
				ERROR: 'DÎrgimi i njoftimit falimentoi '
			},
			TYPE: {
				ALL_ACCOUNTS: 'TÎ gjitha llogaritÎ',
				ALL_USERS: 'TÎ gjithÎ pÎrdoruesit',
				ALL_RIDERS: 'TÎ gjithÎ transportatorÎt',
				ALL_BUSINESS: 'TÎ gjithÎ bizneset',
				ALL_IOS: 'TÎ gjithÎ iOS',
				ALL_ANDROID: 'TÎ gjithÎ android',
			}
		},
		FEEDBACKS: {
			FEEDBACK: 'Feedback',
			FEEDBACKS: 'Feedback',
			FEEDBACKS_LIST: 'Lista Feedback',
			FIELDS: {
				TOT_FEEDBACK: 'Feedback totale',
				COMMENT: 'Komente',
				USER: 'PÎrdorues',
				REQUIRED: 'KÎrkohet',
				TIMING: 'Koha e dorÎzimit',
				QUALITY: 'CilÎsi',
				PACKAGE: 'Paketim'
			},
			ACTIONS: {
				APPROVE: 'Aprovuar',
				REFUSE: 'Refuzuar',
				APPROVED_MESSAGE: 'Feedback aprovuar',
				REFUSED_MESSAGE: 'Feedback refuzuar',
			},
			STATUS: {
				NEW: 'I ri',
				VOTED: 'Votuar',
				APPROVED: 'Aprovuar',
				REFUSED: 'Refuzuar',
			}
		},
		PHONE_FORMAT_ERROR: 'Formati i gabuar i numrit të telefonit',
		CROP: 'Crop'
	}
};
